<template>
  <v-hover #default="{hover}">
    <router-link
        :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
        :to="businessPublicLink('news/' + item.alias)"
        class="noUnderline"
    >
    <v-sheet
        :color="BLOCK_COLOR_PARAM(block , 'tiles-news_card_background' , block.config.style )"
        class="fill-height pointer"
        style="transition: transform 0.3s ease; position: relative"
        :style="`border-radius : ${config.block_radius}px; ${hover ? 'transform: scale(1.01)' : ''}`"
    >
      <v-sheet color="transparent" width="100%" style="position: relative">
        <ws-img
            v-if="item.img && config.display_images"
            :src="item.img"
            :height="config.image_height || 200"
            vertical-position="50"
            horizontal-position="50"
            :image-style="`border-top-left-radius: ${config.block_radius}px !important; border-top-right-radius: ${config.block_radius}px !important; height : ${config.image_height || 200}px  `"
            :contain="item.img_contain"
            auto-size
            :trigger-resize="imageResizeTrigger"
        />

      </v-sheet>


      <div  style="padding: 24px" :style="`padding-bottom : ${34 + (config.block_radius/2)}px`">

        <h4 v-if="config.display_news"
            :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'date')" class="mt-2">{{ MONTH_DAY_TIME(item.date) }}</h4>

        <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'title')" class="mt-4" >{{ item.title }}</h3>

        <h4 v-if="config.display_description"
            :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'description')" class="mt-4">{{ item.description }}</h4>

      </div>

    </v-sheet>
    </router-link>
  </v-hover>
</template>

<script>
export default {
  name: "blockTilesDesign1",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    block  : {
      type : Object,
      default() { return { config : {}} }
    },
    cols : {
      type : Number
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      imageResizeTrigger : 0
    }
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    },
  }
}
</script>

<style scoped>

</style>